import React , {useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { addTestUser } from "../../../api";
import { usStates } from "../../../utitls/constent";


const AddTestUser = () => {

    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        state: Yup.string().required("State is required"),
        emailNotification: Yup.string().required("Email Notification is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), null], "The confirmation password does not match the entered password.")
            .required("Confirm Password is required"),
    });


    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        console.log("values", values)
        const formData = new FormData();
        formData.append("firstname", values.firstName);
        formData.append("lastname", values.lastName);
        formData.append("email", values.email);
        formData.append("state", values.state);
        // const isEmailNotification = values.emailNotification === "true" || values.emailNotification === true ? true : false;
        console.log("data ", typeof values.emailNotification)
        // formData.append("email_notification", isEmailNotification);
        formData.append("email_notification", values.emailNotification);
        formData.append("password", values.password);
        formData.append("confirm_password ", values.confirmPassword);

        
        try {
            const { data } = await addTestUser(formData);
            console.log("🚀 ~ onLoad ~ data:", data);
            // console.log("Success:", response.data);

            if (data?.status === 201) {
                alert("User added successfully!");
                navigate('/user-list')

            }
            resetForm(); // Reset form after successful submission
        } catch (error) {
            console.error("Error:", error);
            alert("Error submitting form");
        }

        setSubmitting(false);
    };


    return (
        <div className="min-h-screen bg-[#e5ebfae5] p-5">
            <div className="mt-12">
                <div className="w-full my-4 text-center bg-lightgray text-lg p-4 rounded-xl md:text-4xl font-bold">
                    Add Test User
                </div>
            </div>

            <div className="mt-2 p-4 flex flex-col items-center">
                <div className="w-full my-3 bg-lightgray p-4 rounded-xl">
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: "",
                            state: "",
                            emailNotification: "",
                            password: "",
                            confirmPassword: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form className="grid grid-cols-2 gap-4">
                                {/* First Name */}
                                <div className="grid">
                                    <label>First Name</label>
                                    <Field
                                        type="text"
                                        name="firstName"
                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    />
                                    <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* Last Name */}
                                <div className="grid">
                                    <label>Last Name</label>
                                    <Field
                                        type="text"
                                        name="lastName"
                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    />
                                    <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* Email */}
                                <div className="grid">
                                    <label>Email</label>
                                    <Field
                                        type="email"
                                        name="email"
                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    />
                                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* State */}
                                <div className="grid">
                                    <label>State</label>
                                    <Field
                                        as="select"
                                        name="state"

                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    >
                                        <option value="" hidden selected disabled>Choose a State</option>
                                        {usStates.map((item, i) => {
                                            return (
                                                <option value={item.abbreviation}>{item.name}</option>
                                            );
                                        })}

                                    </Field>
                                    <ErrorMessage name="state" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* Email Notification */}
                                <div className="grid">
                                    <label>Email Notification</label>
                                    <Field
                                        as="select"
                                        name="emailNotification"
                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    >
                                        <option value="" disabled selected hidden>Select Option</option> {/* Placeholder */}
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </Field>
                                    <ErrorMessage name="emailNotification" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* Password */}
                                <div className="grid">
                                    <label>Password</label>
                                    <Field
                                        type="password"
                                        name="password"
                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    />
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                <div className="grid">
                                    <label>Confirm Password</label>
                                    <Field
                                        type="password"
                                        name="confirmPassword"
                                        className="py-2 px-2 w-[99%] border border-black rounded-md"
                                    />
                                    <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-sm mt-1" />
                                </div>

                                {/* <div className="grid">
                                    <label>Password</label>
                                    <div className="relative">
                                        <Field
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            className="py-2 px-2 w-[99%] border border-black rounded-md"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className="absolute right-3 top-3 text-gray-600"
                                        >
                                            {showPassword ? "👁️" : "🙈"}
                                        </button>
                                    </div>
                                    <ErrorMessage name="password" component="div" className="text-red-500 text-sm mt-1" />
                                </div> */}

                                {/* Submit Button */}
                                <div className="col-span-2 flex justify-center mt-4">
                                    <button
                                        type="submit"
                                        className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 me-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? "Submitting..." : "Submit"}
                                    </button>
                                    <Link to={'/admin-dashboard'}>
                                        <button className="bg-white-500 ms-2 text-black border border-black py-2 px-4 rounded-md hover:bg-blue-600" >
                                            Cancel
                                        </button>
                                    </Link>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default AddTestUser